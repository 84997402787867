<!-- eslint-disable vue/no-v-html !-->
<template>
  <div class="onetime-order-page py-5">
    <div v-if="orderPage && orderPage.id" class="custom-container">
      <div v-if="currentStep === 1" class="orderpage-step">
        <div class="card">
          <div class="card-body p-0">
            <section id="order-header-section">
              <div id="order-header-image">
                <p><img src="https://customer-engine.s3.amazonaws.com/assets/genius.webp"
                    style="margin: 0; padding: 0; width: 100%" class="fr-fic fr-dib fr-fil" /></p>
              </div>
              <div id="order-header-text" class="mt-3">
                <h2 style="margin: 0;"><span><span style="font-size: 30px; color: rgb(88, 88, 88); font-weight: bold;">The "Restaurant Marketing Genius" System</span></span></h2>
                <p>&nbsp;</p>
                <p><span style="color: rgb(102, 102, 102); font-size: 22px;">Get access to the full restaurant marketing genius system and start making $1000+/month setting it up for favorite Bars and Restaurants to help them accelerate their sales.</span>
                </p>
              </div>
              <div id="order-header-image2">
                <p><img src="https://customer-engine.s3.amazonaws.com/assets/money-back.png"
                    style="margin: 0; padding: 0;" class="fr-fic fr-dib fr-fil" /></p>
              </div>
            </section>

            <section id="order-content-section">
              <section id="order-form-section">
                <ValidationObserver v-slot="{ handleSubmit, invalid, touched }" ref="orderForm">
                  <form class="authentication-form" @submit.prevent="handleSubmit(handleRegister)">
                    <h2 class="mt-0 mb-3">Contact Information</h2>
                    <div class="row">
                      <div class="col-md-6">
                        <TextInput v-model="credentials.first_name" placeholder="First Name" rules="required"
                          name="first_name" />
                      </div>
                      <div class="col-md-6">
                        <TextInput v-model="credentials.last_name" placeholder="Last Name" rules="required"
                          name="last_name" />
                      </div>
                      <div class="col-md-6">
                        <TextInput v-model="credentials.email" autocomplete="new-account" placeholder="Email Address"
                          rules="required|email" name="email" />
                      </div>
                      <div class="col-md-6">
                        <PhoneInput v-model="credentials.phone" autocomplete="new-account" placeholder="Phone number"
                          rules="required|phone" name="phone" />
                      </div>
                    </div>
                    <h2 class="mt-4 mb-3">Payment Information</h2>
                    <div v-if="loadingPrices" class="text-center">
                      <b-spinner small />
                    </div>
                    <template v-else>
                      <label class="d-block text-muted mb-2">Select Payment Option</label>
                      <ul v-if="orderPage.config.priceIds && orderPage.config.priceIds.length > 0" class="mb-4">
                        <li v-for="priceId in orderPage.config.priceIds" :key="priceId">
                          <label>
                            <input v-model="paymentOption" type="radio" name="paymentOption" :value="priceId"
                              @change="selectPaymentOption" />
                            <span>{{ getPriceById(priceId).name }}</span>
                            <span>${{ formatPrice(getPriceById(priceId).amount) }}</span>
                          </label>
                        </li>
                      </ul>
                    </template>
                    <AddCreditCard ref="cardForm" />
                    <div class="text-center mt-3 mb-3">
                      <img src="@assets/images/trust-seals.png" alt="Trust" />
                    </div>
                    <section v-if="selectedPrice && selectedPrice.id" id="order-summary-section">
                      <h2 class="mt-4 mb-3">Order Summary</h2>
                      <div class="total-div mb-3">
                        <label>Total:</label>
                        <span>${{ formatPrice(selectedPrice.amount) }}</span>
                      </div>
                      <div class="desc-div mb-3">
                        <p style="line-height: 1.6;"><span>I understand that I will be billed
                            <strong>$<span id="v-checkout-price">397.00</span></strong> today for the live workshop
                            and training program.&nbsp;</span><span>I also understand that I
                            am being given Free Trial Access to the Customer Engine platform for the next 30 days
                            (starting at the time of purchase).</span></p>
                        <p style="line-height: 1.6;"><span>I understand that I can cancel my
                            <span id="isPasted">Customer</span> Engine account anytime and
                            that I will be billed <strong>($<span id="v-subscription-price">197.00</span>)</strong> 30
                            days from now to keep my&nbsp;</span><span><span
                              id="isPasted">Customer</span>&nbsp;</span><span>Engine account active (no action required
                            unless you want to
                            cancel).&nbsp;</span><span>I also understand that I will be
                            billed every 30 days if I decide to remain a <span id="isPasted">Customer</span> Engine
                            subscriber.</span></p>
                        <p style="line-height: 1.6;"><span>I understand that I can contact
                            the <span id="isPasted">Customer</span> Engine Support Team at
                            <a href="https://customerengine.io/support" target="_blank">https://customerengine.io/support</a> at any time to cancel my account or get customer
                            support.</span></p>
                        <p style="line-height: 1.6;"><span>Existing <span id="isPasted">Customer</span> Engine subscribers
                            are not signed
                            up for a new account or trial as you have an account that is already active.</span></p>
                      </div>
                    </section>
                    <b-form-group class="mt-4 mb-0">
                      <b-button variant="primary" class="btn-block" type="submit"
                        :disabled="processing || (invalid && touched)">
                        <span v-if="!processing" style="font-size: 20px;font-weight: bold;">Place Order Now</span>
                        <b-spinner v-else small />
                      </b-button>
                    </b-form-group>
                  </form>
                </ValidationObserver>
              </section>
              <section id="order-details-section">
                <div id="order-details-desc">
                  <h2 style="margin: 0;"><span style="font-size: 24px; color: rgb(0, 0, 0);">What You&#39;re Getting
                      Inside:</span></h2>
                  <p><br></p>
                  <ul style="margin-bottom: 0px;">
                      <li style="color: rgb(138, 154, 95); font-size: 16px; line-height: 1.8;"><strong>Part 1:&nbsp;</strong><strong>The full “Restaurant Marketing Genius” system from 10k feet up.&nbsp;</strong>You'll get a full behind-the-scenes look at all of the pieces of the system and how they fit together to help your favorite restaurant or bar grow faster (and how you can make 1k+/month to deliver that help).<br><br></li>
                      <li style="color: rgb(138, 154, 95); font-size: 16px; line-height: 1.8;"><strong>Part 2: The “Hungry Audience” Restaurant Niche.&nbsp;</strong>You'll discover exactly how we find the right restaurants to offer this service to and why they are ready to pay us to help them grow faster. This will help you make sure that you aren't going after the wrong types of restaurants and that you're spending your time contacting only those that are ready for your Package.<br><br></li>
                      <li style="color: rgb(138, 154, 95); font-size: 16px; line-height: 1.8;"><strong>Part 3: The “New Restaurant Client Magnet”.&nbsp;</strong>You'll learn the exact steps behind delivering the powerful “service” that gets us new clients locked in and ready to sign up for our full recurring package (and how to deliver this to every restaurant you want as a client in just 15 minutes)..<br><br></li>
                      <li style="color: rgb(138, 154, 95); font-size: 16px; line-height: 1.8;"><strong>Part 4: The “Restaurant Growth Machine” Package.&nbsp;</strong>You'll see the exact services we deliver to each client, how they fit together to deliver huge results each month, and how we deliver everything with software, and why they gladly pay us $1k+/month and feel like they took advantage of us.<br><br></li>
                      <li style="color: rgb(138, 154, 95); font-size: 16px; line-height: 1.8;"><strong>Part 5: How to get your first 10 Restaurants signed up in 60 days.&nbsp;</strong>In this section, we'll show you the exact week-by-week roadmap to signing up your first 10 restaurant clients (and then how to leverage those into your next 50 clients) at 1k+/month.<br><br></li>
                      <li style="color: rgb(138, 154, 95); font-size: 16px; line-height: 1.8;"><strong>[Bonus #1] 30 days free Access to the Software that we use to deliver all of the services to our restaurant and bar clients.</strong><br><br></li>
                      <li style="color: rgb(138, 154, 95); font-size: 16px; line-height: 1.8;"><strong>[Bonus #2] The “Restaurant Marketing Genius” outsourcing guide. You'll learn exactly when and how to hire your first employee so you can step out of all of the “Service Delivery Work” and focus only on Growing your client base.&nbsp;</strong><br><br></li>
                      <li style="color: rgb(138, 154, 95); font-size: 16px; line-height: 1.8;"><strong>[Bonus #3] Access to the “Restaurant Marketing Genius” Creative Vault.&nbsp;</strong>You're getting full access to all of the graphic design templates that we use to deliver our package at a high level (Included inside are Social Media Templates, Physical In-Store Templates, as well as a few secret templates that are a must have for anyone using this system).<br><br></li>
                  </ul>
                </div>
                <div id="order-details-images">
                  <ul>
                    <li>
                      <p><img
                          src="https://customer-engine.s3.amazonaws.com/assets/product-order-1.png"
                          class="fr-fic fr-dib" style="margin: 0 auto; padding: 0; height: 80px; object-fit: contain;" />
                      </p>
                      <p style="text-align: center; line-height: 1.15; margin-top: 10px;"><span
                          style="color: rgb(153, 153, 153); font-size: 12px;">60 Day</span></p>
                      <p style="text-align: center; line-height: 1.15;"><span
                          style="color: rgb(153, 153, 153); font-size: 12px;">Money Back</span></p>
                      <p style="text-align: center; line-height: 1.15;"><span
                          style="color: rgb(153, 153, 153); font-size: 12px;">Guarantee</span></p>
                    </li>
                    <li>
                      <p><img
                          src="https://customer-engine.s3.amazonaws.com/assets/product-order-2.png"
                          class="fr-fic fr-dib" style="margin: 0 auto; padding: 0; height: 80px; object-fit: contain;" />
                      </p>
                      <p style="text-align: center; line-height: 1.15; margin-top: 10px;"><span
                          style="color: rgb(153, 153, 153); font-size: 12px;">CustomerEngine</span></p>
                      <p style="text-align: center; line-height: 1.15;"><span
                          style="color: rgb(153, 153, 153); font-size: 12px;">Protects Your</span></p>
                      <p style="text-align: center; line-height: 1.15;"><span
                          style="color: rgb(153, 153, 153); font-size: 12px;">Privacy</span></p>
                    </li>
                    <li>
                      <p><img
                          src="https://customer-engine.s3.amazonaws.com/assets/product-order-3.png"
                          class="fr-fic fr-dib" style="margin: 0 auto; padding: 0; height: 80px; object-fit: contain;" />
                      </p>
                      <p style="text-align: center; line-height: 1.15; margin-top: 10px;"><span
                          style="color: rgb(153, 153, 153); font-size: 12px;">Your</span></p>
                      <p style="text-align: center; line-height: 1.15;"><span
                          style="color: rgb(153, 153, 153); font-size: 12px;">Information</span></p>
                      <p style="text-align: center; line-height: 1.15;"><span
                          style="color: rgb(153, 153, 153); font-size: 12px;">is Secure</span></p>
                    </li>
                  </ul>
                </div>
              </section>
            </section>
          </div>
        </div>
        <p id="order-copyright-text" style="font-size:13px;">
          Copyright {{ year }} All Rights ReservedTerms of ServicePrivacy Policy
          <br />
          11505 Burnham Drive, Suite 204, Gig Harbor, WA 98332
        </p>
      </div>
      <div v-if="currentStep === 2" class="orderpage-step">
        <section class="order-step-2">
          <h2>Congratulation - Your order has been successfully completed.</h2>
          <router-link :to="{
            name: 'agency.businesses.index',
          }" class="btn btn-primary mt-3">
            <span v-if="!processing"><i class="uil uil-enter"></i> Go To Dashboard</span>
            <b-spinner v-else small />
          </router-link>
        </section>
      </div>
    </div>
    <div v-else class="text-center">
      <b-spinner variant="light" large />
    </div>
  </div>
</template>

<script>

import moment from 'moment-timezone'
import AddCreditCard from '../Common/AddCreditCard'

export default {
  components: {
    AddCreditCard,
  },
  props: {
  },
  data() {
    return {
      currentStep: 1,
      orderPage: null,
      pricesList: [],
      credentials: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        payment_method: null,
        price: null,
      },
      selectedPrice: null,
      paymentOption: null,
      loadingPrices: false,
      processing: false,
      year: moment().format('Y'),
    }
  },
  computed: {
    prices() {
      return this.pricesList.filter(
        (price) => price.visible
      ).sort((a, b) => a.amount < b.amount)
    }
  },
  mounted() {
    this.getOrderPage(this.$route.params.slug);
  },
  methods: {
    getOrderPage(slug) {
      this.$store
        .dispatch('auth/getOrderPage', slug)
        .then((res) => {
          if (res) {
            this.orderPage = res
            this.getPrices().then(() => {
              this.initPaymentOption()
            })
          }
        })
        .catch(() => {
        })
    },

    formatPrice(value) {
      return (value / 1).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    getPriceById(priceId) {
      for (let i = 0; i < this.prices.length; i++) {
        if (this.prices[i].id === priceId) {
          return this.prices[i]
        }
      }
      return { name: '', amount: '' }
    },
    getPrices() {
      return new Promise((resolve) => {
        this.loadingPrices = true
        this.$store
          .dispatch('subscription/getAllPrices')
          .then((res) => {
            this.loadingPrices = false
            this.pricesList = res
            resolve(true)
          })
          .catch(() => {
            this.loadingPrices = false
            resolve(false)
          })
      })
    },
    initPaymentOption() {
      if (
        this.orderPage &&
        this.orderPage.config &&
        this.orderPage.config.priceIds &&
        this.orderPage.config.priceIds.length > 0
      ) {
        this.paymentOption = this.orderPage.config.priceIds[0]
        this.selectPaymentOption()
      }
    },
    selectPaymentOption() {
      for (let i = 0; i < this.prices.length; i++) {
        const price = this.prices[i]
        if (price.id === this.paymentOption) {
          this.selectedPrice = price
          break
        }
      }
    },

    async validateCard() {
      this.processing = true
      this.credentials.payment_method = null

      await this.$refs.cardForm
        .validate()
        .then((paymentMethod) => {
          this.processing = false
          this.credentials.payment_method = paymentMethod
        })
        .catch(() => {
          this.processing = false
        })
    },

    async handleRegister() {
      await this.validateCard()
      if (!this.credentials.payment_method) return

      this.credentials.price = this.selectedPrice.id
      this.processing = true
      this.$store
        .dispatch('auth/registerAgencyOnetime', this.credentials)
        .then(() => {
          this.onSuccess()
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.$refs.orderForm.setErrors(err.response.data.errors)
          }
          this.processing = false
        })
    },

    onSuccess() {
      if (this.orderPage && this.orderPage.redirect_url) {
        window.location.href = this.orderPage.redirect_url;
        return;
      }
      this.currentStep = 2
      this.$store
        .dispatch('auth/getProfile')
        .then(() => {
          this.processing = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.onetime-order-page {
  min-height: 100vh;
  padding-left: 12px;
  padding-right: 12px;
  background-color: #000000;

  .custom-container {
    max-width: 1116px;
    width: 100%;
    margin: 0 auto;

    .orderpage-step {
      .order-step-2 {
        max-width: 1116px;
        width: 100%;
        margin: 0 auto;
        border-radius: 0.25rem;
        box-shadow: 0 0.05rem 0.01rem rgb(75 75 90 / 8%);
        background-color: #fff;
        margin-bottom: 24px;
        padding: 50px 30px;
        text-align: center;
      }
    }
  }

  #order-header-section {
    background: #f7f7f7;
    border-bottom: 1px solid #e4e4e4;
    display: flex;
    position: relative;

    #order-header-image {
      padding: 20px 0 20px 20px;
      width: 245px;
      max-width: 100%;
    }

    #order-header-text {
      flex: 1;
      padding: 20px 30px;
    }

    #order-header-image2 {
      padding: 0 50px 20px 0;
      width: 190px;
      max-width: 100%;
    }

    @media all and (max-width: 991px) {
      display: block;

      #order-header-image {
        padding: 20px 20px 0 20px;
        width: 265px;
      }

      #order-header-text {
        padding: 20px;
      }

      #order-header-image2 {
        position: absolute;
        top: 0;
        right: 20px;
        padding: 0;
        width: 80px;
      }
    }
  }

  #order-content-section {
    width: 100%;
    display: flex;

    #order-form-section {
      padding: 30px 40px;
      flex: 0 0 60%;
      width: 60%;

      input[type=text] {
        height: 45px;
      }

      input[type=tel] {
        height: 43px;
      }

      .authentication-form {
        >ul {
          list-style: none;
          padding: 0;

          li {
            >label {
              display: flex;
              align-items: center;
              margin: 0;
              margin-bottom: 1rem;
              padding: 0;
              width: 100%;
              border: 2px solid rgba(0, 0, 0, 0.1);
              cursor: pointer;
              border-radius: 4px;

              >input[type=radio] {
                margin-left: 10px;
                margin-right: 10px;
              }

              >span {
                font-size: 14px;
                color: black;
                font-weight: 400;
              }

              >span:last-child {
                font-size: 18px;
                color: black;
                font-weight: 700;
                border-left: 1px solid #e5e5e5;
                background-color: #f5f5f5;
                margin-left: auto;
                margin-right: 0;
                width: 135px;
                padding: 15px;
              }
            }
          }
        }

        .cardForm {
          .form-control {
            height: calc(1.5rem + 1.5em);
            padding: 0.75rem 0.75rem;
          }
        }

        .text-center {
          >img {
            margin: 0;
            padding: 0;
          }
        }

        #order-summary-section {
          .total-div {
            display: flex;
            align-items: center;
            background-color: #e9e9e9;
            padding: 15px;

            >label,
            >span {
              color: black;
              font-size: 18px;
              font-weight: 700;
              margin: 0;
              padding: 0;
            }

            >span {
              margin-left: auto;
              margin-right: 0;
            }
          }

          .desc-div {
            font-size: 13px;
          }
        }

        button[type=submit] {
          min-height: 50px;
        }
      }
    }

    #order-details-section {
      flex: 0 0 40%;
      width: 40%;
      height: 100%;
      background: #F8FFE2;
      display: flex;
      flex-direction: column;

      #order-details-desc {
        width: 100%;
        flex: 1;
        padding: 30px 20px;
      }

      #order-details-images {
        width: 100%;
        background-color: rgb(249, 249, 249);

        img.fr-dib {
          display: block;
          float: none;
          vertical-align: top;
        }

        p {
          margin-bottom: 0;
        }

        >ul {
          width: 100%;
          list-style: none;
          margin: 0;
          padding: 0;
          display: flex;

          >li {
            flex: 0 0 33.33%;
            width: 33.33%;
            padding-top: 20px;
            padding-bottom: 20px;
          }
        }
      }
    }

    @media all and (max-width: 991px) {
      flex-direction: column-reverse;

      #order-form-section {
        flex: 0 0 100%;
        width: 100%;
        padding: 20px;
      }

      #order-details-section {
        flex: 0 0 100%;
        width: 100%;
      }
    }
  }

  #order-copyright-text {
    text-align: center;
    color: #a1a1a1;
    font-size: 13px;
  }

  @media all and (max-width: 767px) {

    code,
    span,
    p,
    label,
    ul li {
      font-size: 15px !important;
    }

    .h1,
    h1,
    .h1 *,
    h1 * {
      font-size: 28px !important;
    }

    .h2,
    h2,
    .h2 *,
    h2 * {
      font-size: 26px !important;
    }

    .h3,
    h3,
    .h3 *,
    h3 * {
      font-size: 22px !important;
    }

    .h4,
    h4,
    .h4 *,
    h4 * {
      font-size: 20px !important;
    }

    .h5,
    h5,
    .h5 *,
    h5 * {
      font-size: 17px !important;
    }

    .h6,
    h6,
    .h6 *,
    h6 * {
      font-size: 15px !important;
    }
  }
}</style>